import React, { useState, useEffect } from 'react';
import {Modal, List} from 'antd';
import {databaseRef} from '../vendors/firebase';

const ArchivedTimers = (props) => {
  const [archivedTimers, setArchivedTimers] = useState([]);

  const fetchArchivedTimers = () => {
    databaseRef.collection('timers')
      .where("userId", "==", props.currentUser.uid)
      .where('archived', '==', true)
      .get()
      .then(querySnapshot => {
        const timers = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setArchivedTimers(timers);
      })
      .catch(error => console.error("Error fetching archived timers: ", error));
  };

  const unarchiveTimer = (timerId) => {
    databaseRef.collection('timers').doc(timerId).update({
      archived: false
    }).then(() => {
      fetchArchivedTimers(); // Refresh the list after unarchiving
    }).catch(error => console.error("Error unarchiving timer: ", error));
  };

  useEffect(() => {
    if (props.visible) {
      fetchArchivedTimers();
    }
  }, [props.visible]);

  return (
    (<Modal
      onCancel={props.handleArchivedTimers}
      title="Archived Timers"
      open={props.visible}
      footer={null}
    >
      <List
        header={null}
        footer={null}
        bordered
        dataSource={archivedTimers}
        renderItem={(item) => (
          <List.Item actions={[<a onClick={() => unarchiveTimer(item.id)}>Unarchive</a>]}>
            {item.name}
          </List.Item>
        )}
      />
    </Modal>)
  );
}

export default ArchivedTimers;
