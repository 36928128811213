import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import "firebase/compat/analytics";

let databaseURL;
let storageBucket;

if (window.location.hostname === "localhostX") {
  databaseURL = "http://localhost:9000?ns=thymefull";
  storageBucket = "gs://thymefull.appspot.com";
} else {
  databaseURL = "https://thymefull.firebaseio.com";
  storageBucket = "gs://thymefull.appspot.com";
}

const config = {
  apiKey: "AIzaSyBWNhEYQa3ss7brP8nn2aA9JKMRE7a7g3A",
  appId: "1:350571192143:web:0f4243ca37452c50546f3a",
  authDomain: "thymefull.firebaseapp.com",
  databaseURL: databaseURL,
  measurementId: "G-K5Y9RHW2R4",
  messagingSenderId: "350571192143",
  projectId: "thymefull",
  storageBucket: storageBucket,
}

firebase.initializeApp(config);

if (window.location.hostname === "localhostX") {
  // firebase.auth().useEmulator("http://localhost:9099/");
  // databaseRef.useEmulator("localhost", "8080");
  
  firebase.functions().useEmulator("localhost", "5001");
  firebase.firestore().settings({
    host: "localhost:8080",
    ssl: false,
  });
}

const analytics = firebase.analytics();
const databaseRef = firebase.firestore();
const storage = firebase.storage();

export {
  analytics,
  databaseRef,
  firebase,
  storage,
}