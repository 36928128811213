function email(email) {
  const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  if (email.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}

function password(password) {
  if (password.length >= 6) {
    return true;
  } else {
    return false;
  }
}

export { email, password }