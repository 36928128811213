import moment from 'moment';
import "vendors/modernizr";

const { Modernizr } = window;

function setup() {
  if (Modernizr.notification) {
    if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          let notification = new Notification("Thymefull notifications turned on!");
          return notification;
        }
      });
    }
  }
}

function send(title, body) {
  if (Modernizr.notification) {
    if (Notification.permission === "granted") {
      const options = {
        body: body,
      }
  
      const notification = new Notification(title, options);
  
      return notification;
    }
  }
}

function sendForActivity(usersChanged, usersCurrent) {
  usersChanged.forEach((change) => {
    const following = usersCurrent.filter((user) => {
      return user.id === change.doc.id;
    })

    if (following[0] !== undefined) {
      if (change.doc.data().timerRunning !== following[0].timerRunning) {
        const name = change.doc.data().displayName;

        if (change.doc.data().timerRunning) {
          send(name, "Now focused.");
        } else {
          send(name, "Now free.");
        }
      }
    }
  });
}

function sendForInterval(timer) {
  const hours = moment.duration(timer.alertInterval, "milliseconds").asHours();
  send(timer.name, `Running for ${hours} hours.`);
}

function sendForComplete(timer) {
  const duration = moment.duration(timer.duration, "milliseconds").asHours();
  send(timer.name, `Completed week's goal of ${duration} hours.`);
}

export {setup, send, sendForActivity, sendForInterval, sendForComplete}