function forPositions(a, b) {
  const positionA = a.position;
  const positionB = b.position;

  let comparison = 0;

  if (positionA > positionB) {
    comparison = 1;
  } else if (positionA < positionB) {
    comparison = -1;
  }

  return comparison;
}
  
function forStatus(a, b) {
  const statusA = a.status;
  const statusB = b.status;

  let comparison = 0;

  if (statusA < statusB) {
    comparison = 1;
  } else if (statusA > statusB) {
    comparison = -1;
  }

  return comparison;
}

export { forPositions, forStatus }