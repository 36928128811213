import React from 'react';
import Timer from 'components/Timer.js'
import TimerShow from "components/TimerShow.js"
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {Empty, Typography, Row, Col} from 'antd';

const { Paragraph } = Typography;

const getItemStyle = (isDragging, draggableStyle) => ({
  transform: isDragging ? 'scale(1.2)' : 'scale(1)',
  // background: isDragging ? "rgb(235, 235, 235)" : "none",
  marginBottom: "10px",
  
  ...draggableStyle
});

class TimerList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sessionBeingEdited: undefined,
      showSessionForm: false,
      showTimer: undefined,
    }
  }

  handleTimerShow = (timer) => {
    this.setState({
      showTimer: timer === undefined ? undefined : timer
    })
  }

  render() {
    let content;

    if (this.props.timers.length === 0) {
      content = (
        <Empty
          image={null}
          imageStyle={{
            height: 250,
          }}
          description={
            <Row>
              <Col xs={{span: 20, offset: 2}} md={{span: 12, offset: 6}}>
                <Paragraph strong>
                  You have no timers.
                </Paragraph>
                <Paragraph>
                  Start making progress by creating a new timer. Click the little circle button with the plus sign above.
                </Paragraph>
              </Col>
            </Row>
          }
          style={{marginTop: "24px"}}
        >
          {/* <Button type="primary" onClick={() => this.props.handleTimerForm()} style={{margin: "15px 0 10px 0"}}>New Timer</Button> */}
        </Empty>
      )
    } else {
      content = (
        <DragDropContext onDragEnd={this.props.onDragEnd} className="timer-list">
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
  
              <div
                className="timer-list"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {this.props.timers.map((timer, index) => {
                  return(
                    <Draggable key={timer.id} draggableId={timer.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Timer
                            activeSessionElapsed={timer.activeSessionElapsed}
                            duration={timer.duration}
                            elapsed={timer.elapsed}
                            handleTimerStatusChange={this.props.handleTimerStatusChange}
                            handleSessionForm={this.props.handleSessionForm}
                            handleStop={this.props.handleStop}
                            handleTimerForm={this.props.handleTimerForm}
                            handleShowTimer={this.handleTimerShow}
                            id={timer.id}
                            key={timer.id}
                            name={timer.name}
                            ownerView={true}
                            position={timer.position}
                            running={timer.running}
                            timer={timer}
                            updateTimer={this.props.updateTimer}
                            userId={timer.userId}
                            activeSessionStartedAt={timer.activeSessionStartedAt}
                          />

                          <TimerShow
                            currentUser={this.props.currentUser}
                            handleSessionForm={this.handleSessionForm}
                            handleShowTimer={this.handleTimerShow}
                            handleTimerForm={this.props.handleTimerForm}
                            timer={timer}
                            visible={this.state.showTimer === timer}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )
    }

    return(
      <>
        {content}
      </>
    )
  }
}

export default TimerList;