import React, { useState } from 'react';
import {firebase, databaseRef} from '../vendors/firebase';
import * as validators from '../helpers/validators';

import {Divider, Modal, Form, Input, Checkbox} from 'antd';

const AccountForm = (props) => {
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [form] = Form.useForm();

  const updateAccount = () => {
    form.validateFields()
      .then((values) => {
        const credential = firebase.auth.EmailAuthProvider.credential(values.currentEmail, values.currentPassword);

        props.currentUser.reauthenticateWithCredential(credential)
          .then(() => {
            if (values.newEmail !== undefined && values.newEmail.length >= 6) {
              props.currentUser.updateEmail(values.newEmail)
                .then(() => {
                  databaseRef.collection("users").doc(props.currentUser.uid).update({email: values.newEmail})
                })
            }
    
            if (values.newPassword !== undefined && values.newPassword.length >= 6) {
              props.currentUser.updatePassword(values.newPassword)
            }

            if (values.deleteAccount) {
              props.currentUser.delete()
            }
          })
          .catch((error) => {
            console.log(error);
          });

          handleAccountForm();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const validateEmail = (email) => {
    if (validators.email(email)) {
      if (email === props.currentUser.email) {
        return true;
      } else {
        return databaseRef.collection("users").where("email", "==", email).get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length === 0) {
              return true;
            } else {
              return false;
            }
          });
      }
    } else {
      return false;
    }
  }

  const handleAccountForm = () => {
    form.resetFields();
    setDeleteAccount(false);
    props.handleAccountForm();
  }

  const handleDeleteAccount = () => {
    setDeleteAccount(!deleteAccount);
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    (<Modal
      okText={deleteAccount ? "Delete Account" : "Save"}
      okType={deleteAccount ? "danger" : "primary"}
      onCancel={handleAccountForm}
      onOk={updateAccount}
      title="Account"
      open={props.visible}
    >
      <Form
        form={form}
        name="account-form"
        {...layout}
      >
        <Form.Item
          name="newEmail"
          label="Change Email"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || validateEmail(getFieldValue('newEmail'))) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('Your email is not valid.');
                }
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label="Change Password"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || validators.password(getFieldValue('newPassword'))) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('Your password must be at least 6 characters.');
                }
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="deleteAccount"
          label="Delete Account"
          valuePropName="checked"
        >
          <Checkbox onChange={handleDeleteAccount}></Checkbox>
        </Form.Item>

        <Divider plain>Verify Credentials</Divider>

        <Form.Item
          name="currentEmail"
          label="Current Email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="currentPassword"
          label="Current Password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>)
  );
}

export default AccountForm;