function addIdsToObjects(querySnapshot) {
  let objects = [];
        
  querySnapshot.forEach((doc) => {
    let object = doc.data();
    object.id = doc.id;
    objects.push(object);
  });

  return objects
}

export {
  addIdsToObjects
}