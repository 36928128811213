import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { unregister as unregisterServiceWorker } from './serviceWorker';
import Landing from 'pages/Landing';
import Authentication from 'pages/Authentication';
import Dashboard from 'pages/Dashboard';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from 'components/ProtectedRoute';
import { ConfigProvider } from 'antd';
import enGB from 'antd/es/locale/en_GB';
import moment from 'moment';

import 'moment/locale/en-gb';
import 'styles/index.less';

moment.locale('en-gb');

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <ConfigProvider locale={enGB}>
      <AuthProvider>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/authentication' element={<Authentication />} />
          <Route path='/dashboard' element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
        </Routes>
      </AuthProvider>
    </ConfigProvider>
  </BrowserRouter>
);

// Service worker registration/unregistration
unregisterServiceWorker();
