import React from "react";
import "styles/TimeBar.less";
import moment from 'moment';
import 'moment-duration-format';

function timeLeftInUnitAsPercent(duration) {
  const weekDuration = moment.duration(604800000).asMilliseconds();
  const timeLeftAsPercent = (duration / weekDuration) * 100;

  return timeLeftAsPercent >= 0 ? timeLeftAsPercent : 0;
}

function timeAsFormatted(time, withSeconds = false) {
  if (withSeconds) {
    return moment.duration(time).format('h:mm:ss', {trim: true});
  } else {
    return moment.duration(time).format('h:mm', {trim: true});
  }
}

const TimeBar = (props) => {
  const timeLeftInWeekAsPercent = (time) => {
    return (time / 604800000) * 100;
  }

  return(
    <div id="timebar" style={props.style}>
      <div className="timebar-time-left-of-planned" style={{width: timeLeftInWeekAsPercent(props.timeLeftInWeekOfPlanned) + '%'}}></div>

      <div className="timebar-info" style={{marginLeft: timeLeftInUnitAsPercent(props.timeLeftInWeekOfPlanned) + '%'}}>
        <div className="time-left-in-week">
          {timeAsFormatted(props.timeLeftInWeekOfPlanned)}
        </div>
      </div>
    </div>
  )
}

export default TimeBar;