import React from 'react';
import {firebase, databaseRef} from '../vendors/firebase';
import * as validators from '../helpers/validators';
import {Avatar, Modal, Form, Select, List, Button, Empty} from 'antd';
import algoliasearch from "algoliasearch";

function pendingFollowerRequestsAndInvitesMessage(user) {
  if (user.invited === false) {
    return `You requested to follow ${user.email}.`
  } else {
    return `You invited ${user.recipientEmail}.`
  }
}

function displayNameOrEmail(user) {
  return user.displayName || user.email
}

class FollowForm extends React.Component {
  constructor(props) {
    super(props);

    this.client = algoliasearch("3CYIF643JB", "a8633be18a9d55dfc87be091b19fe010");
    this.index = this.client.initIndex('users-production');

    this.formRef = React.createRef();

    this.state = {
      data: [],
      showNoContentMessage: false,
    }
  }

  handleSearch = (value) => {
    if (value.length >= 3) {
      this.index.search(value)
      .then((response) => {
        let users = [];
        let showNoContentMessage;

        if (response.hits.length !== 0) {
          response.hits.forEach((hit) => {
            let user = hit;

            const sameAsCurrentUser = user.objectID === this.props.currentUser.uid;
            const existingRequest = this.props.requestsAsSender.some(existingRequest => existingRequest.id === user.objectID);
            const existingFollowee = this.props.followees.some(followee => followee.id === user.objectID);
  
            if (sameAsCurrentUser || existingRequest || existingFollowee) {
              user.disabled = true;
            } else {
              user.disabled = false;
            }
  
            users.push(user);
          });
        } else {
          const existingInvite = this.props.invitesAsSender.some(invite => invite.recipientEmail === value);

          if (existingInvite) {
            users.push({email: value, disabled: true, displayName: value});
          }

          if (validators.email(value)) {
            console.log("TRUE");
            showNoContentMessage = true;
          } else {
            showNoContentMessage = false;
          }
        }

        this.setState({
          data: users,
          showNoContentMessage: showNoContentMessage,
        });
    
        this.formRef.current.setFieldsValue({
          user: value,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      this.setState({
        data: [],
        showNoContentMessage: false,
      });
  
      this.formRef.current.setFieldsValue({
        user: value,
      });
    }
  };

  handleSubmit = () => {
    this.formRef.current
      .validateFields()
      .then((values) => {
        this.formRef.current.resetFields();

        databaseRef.collection("users").where("email", "==", values.user).get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              databaseRef.collection("users").doc(doc.id).update({
                requestsAsRecipient: firebase.firestore.FieldValue.arrayUnion(this.props.currentUser.uid)
              });
            });
          })
      })
      .catch((info) => {
        console.log(info);
      });
  }

  handleInvite = () => {
    this.formRef.current
      .validateFields()
      .then((values) => {
        this.formRef.current.resetFields();

        databaseRef.collection("invites").add({
          invitedAt: firebase.firestore.Timestamp.now(),
          recipientEmail: values.user,
          recipientSignedUp: false,
          senderId: this.props.currentUser.uid,
        })
      })
      .catch((info) => {
        console.log(info);
      });
  }

  handleCancel(user) {
    if (user.invited === false) {
      databaseRef.collection("users").doc(user.id).update({
        requestsAsRecipient: firebase.firestore.FieldValue.arrayRemove(this.props.currentUser.uid)
      })
        .catch((error) => {
          console.error(error);
        });
    } else {
      databaseRef.collection("invites").where("senderId", "==", this.props.currentUser.uid).where("recipientEmail", "==", user.recipientEmail).get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.delete();
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  render() {
    let invitesAsSender;
    let modalBodyStyle;
    let notContentFound;
    let requestsAsSender;

    const { Option } = Select;

    const layout = {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
    };

    if (this.state.showNoContentMessage) {
      notContentFound = (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={
            <span>
              The email you entered is not associated with an account.
            </span>
          }
        >
          <Button type="primary" onClick={this.handleInvite}>Invite</Button>
        </Empty>
      )
    } else {
      notContentFound = null;
    }

    const options = this.state.data.map((user) => <Option key={user.email} disabled={user.disabled}>{displayNameOrEmail(user)}</Option>);

    if (this.props.invitesAsSender.length > 0) {
      modalBodyStyle = null;

      invitesAsSender = (
        <List
          header={<div>Invites</div>}
          itemLayout="horizontal"
          dataSource={this.props.invitesAsSender}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      color: "white",
                      backgroundColor: "lightgray"
                    }}
                  >
                    {item.recipientEmail[0].toUpperCase()}
                  </Avatar>
                }
                description={
                  <>
                    <div>{pendingFollowerRequestsAndInvitesMessage(item)}</div>
                    <Button type="link" size="small" danger onClick={() => { this.handleCancel(item) }}>Cancel</Button>
                  </>
                }
              />
            </List.Item>
          )}
        />
      )
    } else {
      modalBodyStyle = {paddingBottom: "0"};
    }

    if (this.props.requestsAsSender.length > 0) {
      modalBodyStyle = null;

      requestsAsSender = (
        <List
          header={<div>Requests</div>}
          itemLayout="horizontal"
          dataSource={this.props.requestsAsSender}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      color: "white",
                      backgroundColor: "lightgray"
                    }}
                  >
                    {item.email[0].toUpperCase()}
                  </Avatar>
                }
                description={
                  <>
                    <div>{pendingFollowerRequestsAndInvitesMessage(item)}</div>
                    <Button type="link" size="small" danger onClick={() => { this.handleCancel(item) }}>Cancel</Button>
                  </>
                }
              />
            </List.Item>
          )}
        />
      )
    } else {
      modalBodyStyle = {paddingBottom: "0"};
    }

    return (
      (<Modal
        bodyStyle={modalBodyStyle}
        open={this.props.show}
        title="Follow your family and friends"
        onCancel={this.props.handleFollowForm}
        footer={[
          <Button key="back" onClick={this.props.handleFollowForm}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleSubmit}>
            Submit
          </Button>,
          <div style={{textAlign: "left"}}>
            {invitesAsSender}
            {requestsAsSender}
          </div>,
        ]}
      >
        <Form
          ref={this.formRef}
          name="follow-form"
          {...layout}
        >
          <Form.Item
            name="user"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Search or invite by email"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={this.handleSearch}
              allowClear
              notFoundContent={notContentFound}
            >
              {options}
            </Select>
          </Form.Item>
        </Form>
      </Modal>)
    );
  }
}

export default FollowForm;