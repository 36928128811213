import React from "react";
import SessionList from "components/SessionList.js";
import {Modal} from "antd";
import "../styles/TimerShow.css";

function TimerShow(props) {
  return (
    (<Modal
      footer={null}
      onCancel={props.handleShowTimer}
      title={props.timer.name}
      open={props.visible}
    >
      <SessionList
        currentUser={props.currentUser}
        handleSessionForm={props.handleSessionForm}
        handleTimerForm={props.handleTimerForm}
        timer={props.timer}
      />
    </Modal>)
  );
}

export default TimerShow;