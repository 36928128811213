import React from 'react';
import moment from 'moment';
import 'moment-duration-format';
import 'moment-timezone';
import { storage, databaseRef } from '../vendors/firebase';

import ImgCrop from 'antd-img-crop';
import { Button, Avatar, Upload, Modal, Form, Input, Select } from 'antd';
import { CameraOutlined } from '@ant-design/icons';

const SettingsForm = (props) => {
  const [form] = Form.useForm();

  const initialValues = {
    dailySleepingHours: moment.duration(props.dailySleepingHours).asHours(),
    displayName: props.currentUser.displayName,
    photoURL: props.currentUser.photoURL,
    timeZone: props.timeZone || moment.tz.guess(),
  }

  const updateAvatar = (request) => {
    const file = request.file;

    storage.ref(`${props.currentUser.uid}/avatar.${file.name.split('.').pop().toLowerCase()}`).put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL()
          .then((downloadURL) => {
            props.currentUser.updateProfile({
              photoURL: downloadURL,
            })
              .then(() => {
                databaseRef.collection("users").doc(props.currentUser.uid).update({
                  photoURL: downloadURL,
                });
              })
              .catch((error) => {
                console.error(error);
              });
          });
      });
  }

  const updateSettings = () => {
    form.validateFields()
      .then((values) => {
        const userRef = databaseRef.collection("users").doc(props.currentUser.uid);

        if (initialValues.displayName !== values.displayName) {
          props.currentUser.updateProfile({
            displayName: values.displayName
          })
            .then(() => {
              userRef.update({
                displayName: values.displayName
              })
            })
        }

        userRef.update({
          dailySleepingHours: moment.duration(Number(values.dailySleepingHours), "hours").asMilliseconds(),
          timeZone: values.timeZone.replace(/ /g, "_"),
        })

        props.handleSettingsForm();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  };

  const { Option } = Select;

  const timeZones = moment.tz.names().map((timeZone) => <Option key={timeZone.replace(/_/g, " ")}>{timeZone.replace(/_/g, " ")}</Option>);

  return (
    (<Modal
      okText="Save"
      onCancel={props.handleSettingsForm}
      onOk={updateSettings}
      title="Settings"
      open={props.visible}
    >
      <div style={{textAlign: "center", marginBottom: "20px"}}>
        <div style={{position: "relative", display: "inline-block"}}>
          <Avatar
            style={{
              backgroundColor: "gray",
              color: "white",
            }}
            src={props.photoURL}
            size={100}
          >
            {props.currentUser.email[0].toUpperCase()}
          </Avatar>

          <ImgCrop
            modalTitle="Edit Avatar"
            cropShape="round"
          >
            <Upload 
              name="avatar"
              customRequest={updateAvatar}
              listType="picture"
              showUploadList={false}
            >
              <Button shape="circle" icon={<CameraOutlined />} style={{position: "absolute", right: "0", bottom: "0"}} />
            </Upload>
          </ImgCrop>
        </div>
      </div>
      <Form
        form={form}
        name="settings-form"
        {...layout}
        initialValues={initialValues}
      >
        <Form.Item
          name="displayName"
          label="Name"
          rules={[
            {
              required: false,
              message: 'Please input your name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="dailySleepingHours"
          label="Sleeping Hours Per Day"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="How many hours of sleep do get each night?" />
        </Form.Item>

        <Form.Item
          name="timeZone"
          label="Time Zone"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            value={initialValues.timeZone}
            placeholder="What is your current time zone?"
            showSearch
          >
            {timeZones}
          </Select>
        </Form.Item>
      </Form>
    </Modal>)
  );
}

export default SettingsForm;