import firebase from "firebase/compat/app";
import "firebase/compat/database";

function startDetection(currentUser) {
  var userStatusDatabaseRef = firebase.database().ref('/users/' + currentUser.uid);

  var isOfflineForDatabase = {
    status: 'offline',
    statusUpdatedAt: firebase.database.ServerValue.TIMESTAMP,
  };
  
  var isOnlineForDatabase = {
    status: 'online',
    statusUpdatedAt: firebase.database.ServerValue.TIMESTAMP,
  };
  
  firebase.database().ref('.info/connected').on('value', function(snapshot) {
    if (snapshot.val() === false) {
      return null;
    };
  
    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
      userStatusDatabaseRef.set(isOnlineForDatabase);
    });
  });
}

export { startDetection }