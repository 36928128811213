import React from 'react';
import moment from 'moment';
import 'moment-duration-format';
import { databaseRef } from '../vendors/firebase';

import { Button, Modal, Form, Input } from 'antd';

class TimerForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }
  
  componentDidUpdate(prevProps) {
    // TODO: Remove setTimeout and use portal
    // https://stackoverflow.com/questions/44074747/componentdidmount-called-before-ref-callback
    setTimeout(() => {
      if (this.props.timer !== prevProps.timer) {
        if (this.props.timer !== undefined) {
          this.formRef.current.setFieldsValue({
            alertInterval: moment.duration(this.props.timer.alertInterval, "milliseconds").asHours(),
            duration: moment.duration(this.props.timer.duration, "milliseconds").asHours(),
            name: this.props.timer.name,
          });
        } else {
          this.formRef.current.setFieldsValue({
            alertInterval: null,
            duration: null,
            name: ''
          });
        }
      }
    }, 300);
  }

  handleSave = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        this.formRef.current.resetFields();

        if (this.props.timer === undefined) {
          const timer = {
            activeSessionElapsed: 0,
            activeSessionId: null,
            activeSessionStartedAt: null,
            alertInterval: moment.duration(Number(values.alertInterval), "hours").asMilliseconds(),
            archived: false,
            completeNotificationSent: false,
            duration: moment.duration(Number(values.duration), "hours").asMilliseconds(),
            elapsed: 0,
            intervalNotificationSent: false,
            name: values.name,
            position: null,
            running: false,
            userId: this.props.currentUser.uid,
          }

          databaseRef.collection("timers").add(timer)
            .catch((error) => {
              console.error(error);
            });
        } else {
          const timer = {
            alertInterval: moment.duration(Number(values.alertInterval), "hours").asMilliseconds(),
            duration: moment.duration(Number(values.duration), "hours").asMilliseconds(),
            name: values.name,
          }

          databaseRef.collection("timers").doc(this.props.timer.id).update(timer)
            .catch((error) => {
              console.error(error);
            });
        }
        
        this.props.handleTimerForm();
      })
      .catch((info) => {
        console.log(info);
      });
  }

  handleArchive = () => {
    databaseRef.collection("timers").doc(this.props.timer.id).update({archived: true})
      .then(() => {
        this.props.handleTimerForm();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // handleDelete = () => {
  //   databaseRef.collection("timers").doc(this.props.timer.id).delete()
  //     .then(() => {
  //       this.props.handleTimerForm();
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }

  handleCancel = () => {
    this.formRef.current.resetFields();
    this.props.handleTimerForm();
    this.props.handleTimerShow();
  }

  render() {
    const header = this.props.timer === undefined ? "New Timer" : "Edit Timer";

    const layout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
    };

    let footer = [
      <Button key="cancel" onClick={this.handleCancel}>
        Close
      </Button>,
      <Button key="ok" type="primary" onClick={this.handleSave}>
        Save
      </Button>,
    ];

    if (this.props.timer !== undefined) {
      const archiveButton = (
        <Button key="delete" type="link" alert onClick={this.handleArchive}>
          Archive
        </Button>
      );

      footer.unshift(archiveButton);
    };

    // if (this.props.timer !== undefined) {
    //   const deleteButton = (
    //     <Button key="delete" type="link" danger onClick={this.handleDelete}>
    //       Delete
    //     </Button>
    //   );

    //   footer.unshift(deleteButton);
    // };

    return (
      (<Modal
        footer={footer}
        okText="Save"
        onCancel={this.handleCancel}
        onOk={this.handleSave}
        title={header}
        open={this.props.visible}
        zIndex={1001}
      >
        <Form
          ref={this.formRef}
          name="timer-form"
          {...layout}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="e.g., Learn Spanish" />
          </Form.Item>
  
          <Form.Item
            name="duration"
            label="Hours Per Week"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="e.g., 5" />
          </Form.Item>

          <Form.Item
            name="alertInterval"
            label="Alert Interval"
            rules={[
              {
                required: true,
              },
            ]}
            help="If you want to be alerted after a given interval, you can enter that interval here. Leave it 0 if you don't want to be alerted."
          >
            <Input placeholder="e.g., .5" />
          </Form.Item>
        </Form>
      </Modal>)
    );
  }
}

export default TimerForm;