import moment from 'moment';
import 'moment-timezone';

function timeLeftInWeek(timeZone) {  
  const now = moment.tz(timeZone);
  const endOfWeek = now.clone().endOf('week');

  return Math.abs(now.diff(endOfWeek));
}

function wakingTimeLeftInWeek(dailySleepingHoursAsMilliseconds, timeZone) {
  const sleepRatio = (moment.duration(dailySleepingHoursAsMilliseconds).asHours() * 7) / 168;

  return timeLeftInWeek(timeZone) * (1 - sleepRatio);
}

function timeLeftInWeekOfPlanned(timers) {
  let totalDuration = 0;

  for (let timer of timers) {
    let timeLeft = timer.duration - (timer.elapsed + timer.activeSessionElapsed);
    timeLeft = timeLeft >= 0 ? timeLeft : 0;
    totalDuration += timeLeft;
  }

  return totalDuration;
}

export {
  timeLeftInWeek,
  timeLeftInWeekOfPlanned,
  wakingTimeLeftInWeek,
}