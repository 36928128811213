import React from "react";
import * as comparisons from "helpers/comparisons";
import { firebase, databaseRef } from "../vendors/firebase";
import * as timeCalculators from "helpers/timeCalculators";
import TimeBar from "components/TimeBar.js";
import Timer from "components/Timer.js";
import TimeLeft from "components/TimeLeft.js";
import { Modal, Button, Empty } from "antd";

function displayNameOrEmail(user) {
  return user.displayName || user.email
}

class UserShow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dailySleepingHours: this.props.user.dailySleepingHours,
      timers: [],
    }
  }

  componentDidMount() {
    databaseRef.collection("timers")
      .where("userId", "==", this.props.user.id)
      .where("archived", "==", false)
      .onSnapshot((querySnapshot) => {
        let timers = [];

        querySnapshot.forEach((doc) => {
          let timer = doc.data();
          timer.id = doc.id;
          timers.push(timer);
        });

        this.setState({
          timers: timers.sort(comparisons.forPositions),
        });
      })
  }

  handleUnfollow = (userId) => {
    databaseRef.collection("users").doc(userId).update({
      followers: firebase.firestore.FieldValue.arrayRemove(this.props.currentUser.uid)
    })
      .then(() => {
        this.props.handleUserShow(null)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    let timerList;

    if (this.state.timers.length > 0) {
      timerList = (
        <>
          {this.state.timers.map((timer, index) => {
            return(
              <Timer
                activeSessionElapsed={timer.activeSessionElapsed}
                duration={timer.duration}
                elapsed={timer.elapsed}
                id={timer.id}
                key={timer.id}
                name={timer.name}
                ownerView={false}
                position={timer.position}
                running={timer.running}
                style={{marginBottom: "4px"}}
                userId={timer.userId}
                activeSessionStartedAt={timer.activeSessionStartedAt}
              />
            )
          })}
        </>
      )
    } else {
      timerList = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{marginTop: "70px"}}/>;
    }

    return (
      (<Modal
        title={displayNameOrEmail(this.props.user)}
        open={this.props.visible}
        onCancel={() => { this.props.handleUserShow(null)}}
        footer={null}
        bodyStyle={{padding: "0 0 0 0", backgroundColor: "rgb(242, 240, 241)"}}
        width={640}
      >
        <TimeLeft
          showWakingTime={this.props.user !== null}
          style={{marginBottom: "60px"}}
          timeLeftInWeek={timeCalculators.timeLeftInWeek(this.props.user.timeZone)}
          wakingTimeLeftInWeek={timeCalculators.wakingTimeLeftInWeek(this.state.dailySleepingHours, this.props.user.timeZone)}
        >
          <TimeBar
            timeLeftInWeekOfPlanned={timeCalculators.timeLeftInWeekOfPlanned(this.state.timers)}
            style={{marginBottom: "4px"}}
          />
        </TimeLeft>
        <div style={{marginBottom: "-4px"}}>
          {timerList}
        </div>
        <Button type="link" danger block onClick={() => { this.handleUnfollow(this.props.user.id) }}>Unfollow</Button>
      </Modal>)
    );
  }
}

export default UserShow;