import React, { createContext, useContext, useState, useEffect } from 'react';
import { firebase, databaseRef } from 'vendors/firebase';
import * as presence from 'services/presence';
import * as notifications from 'helpers/notifications';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      setCurrentUser(user);

      if (user) {
        presence.startDetection(user);
        notifications.setup();

        databaseRef
          .collection('customers')
          .doc(user.uid)
          .collection('subscriptions')
          .where('status', 'in', ['trialing', 'active'])
          .onSnapshot(snapshot => {
            const subscriptionData = snapshot.docs.map(doc => doc.data())[0];

            setSubscription(subscriptionData);
            setLoading(false);
          });
      } else {
        setSubscription(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, subscription, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
