import {firebase} from "./firebase";
import * as firebaseui from "firebaseui";

const ui = new firebaseui.auth.AuthUI(firebase.auth());

let signInSuccessUrl;

if (window.location.hostname === "localhost") {
  signInSuccessUrl = "https://547c9addb88c.ngrok.io";
} else {
  signInSuccessUrl = "https://thymefull.com/dashboard";
}

const uiConfig = {
  signInSuccessUrl: signInSuccessUrl,
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  tosUrl: '/terms-of-service.pdf',
  privacyPolicyUrl: '/privacy-policy.pdf'
}

const startFirebaseUI = (elementId) => {ui.start(elementId, uiConfig)};
const resetFirebaseUI = () => {ui.reset()};

export {
  startFirebaseUI,
  resetFirebaseUI,
}