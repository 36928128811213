import React from 'react';
import moment from 'moment';
import "moment-duration-format";
import "styles/TimeLeft.css";

class TimeLeft extends React.Component {
  timeLeftInWeekAsPercent(time) {
    return (time / 604800000) * 100;
  }
  
  timeAsFormatted(time) {
    return moment.duration(time).format('hh:mm');
  }

  render() {
    const timeLeftInWeek = this.props.timeLeftInWeek;
    const timeLeftInWeekAsPercent = this.timeLeftInWeekAsPercent(timeLeftInWeek);

    const totalTimeLeftFlagStyle = {
      right: "-" + (54 - (54 * (timeLeftInWeekAsPercent / 100)))  + "px",
    };

    let wakingTimeLeftFlag;

    if (this.props.showWakingTime) {
      const wakingTimeLeftInWeek = this.props.wakingTimeLeftInWeek;
      const wakingTimeLeftInWeekAsPercent = this.timeLeftInWeekAsPercent(wakingTimeLeftInWeek);

      const wakingTimeLeftFlagStyle = {
        right: "-" + (54 - (54 * (wakingTimeLeftInWeekAsPercent / 100)))  + "px",
      };

      wakingTimeLeftFlag = (
        <div className="timeleft-waking-time-left-of-unit" style={{left: wakingTimeLeftInWeekAsPercent + '%'}}>
          <span id="waking-time-left" className="timeleft-time-left-formatted" style={wakingTimeLeftFlagStyle}>
            <span className="time">{this.timeAsFormatted(wakingTimeLeftInWeek)}</span>
          </span>
        </div>
      )
    }

    return(
      <div id="timeleft" style={this.props.style}>
        {this.props.children}
        <div className="timeleft-time-left-of-unit" style={{left: timeLeftInWeekAsPercent + '%'}}>
          <span id="total-time-left" className="timeleft-time-left-formatted" style={totalTimeLeftFlagStyle}>
            <span className="time">{this.timeAsFormatted(timeLeftInWeek)}</span>
          </span>
        </div>
        {wakingTimeLeftFlag}
      </div>
    )
  }
}

export default TimeLeft;