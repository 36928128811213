import React from 'react';
import moment from 'moment';
import 'moment-duration-format';
import {PlayCircleOutlined, PauseCircleOutlined} from '@ant-design/icons';
import "../styles/Timer.less"

function timeLeftInUnitAsPercent(elapsed, duration) {
  const weekDuration = moment.duration(604800000).asMilliseconds();
  const timeLeft = duration - elapsed;
  const timeLeftAsRatio = timeLeft / duration;
  // const timeLeftAsPercent = (timeLeft / timeLeftAsRatio) * 100;
  const timeLeftAsPercent = (timeLeft / weekDuration) * 100;

  return timeLeftAsPercent >= 0 ? timeLeftAsPercent : 0;
}

// const timeLeftInWeekAsPercent = (time) => {
//   return (time / 604800000) * 100;
// }

// function timeOverageInUnitAsPercent(elapsed, duration) {
//   const timeLeft = duration - elapsed;
//   const timeLeftAsPercent = (timeLeft / duration) * 100;
//   return timeLeftAsPercent <= 0 ? Math.abs(timeLeftAsPercent) : 0;
// }

function timeAsFormatted(time, withSeconds = false) {
  if (withSeconds) {
    return moment.duration(time).format('HH:mm:ss', {trim: false});
  } else {
    return moment.duration(time).format('h:mm', {trim: true});
  }
}

const Timer = (props) => {
  const handleTimerStatusChange =() => {
    props.handleTimerStatusChange(props.id);
  }

  let button;
  let activeSession;
  let activeSessionElapsed;

  if (props.ownerView) {
    if (props.running) {
      button = <PauseCircleOutlined onClick={handleTimerStatusChange} className="stop" style={{color: 'white'}} />
    } else {
      button = <PlayCircleOutlined onClick={handleTimerStatusChange} className="play" style={{color: 'white'}} />
    }

    activeSessionElapsed = props.activeSessionElapsed;
  } else {
    if (props.activeSessionStartedAt !== null) {
      activeSessionElapsed = Math.abs(moment(props.activeSessionStartedAt.toDate()).diff(moment()));
    } else {
      activeSessionElapsed = 0;
    }
  }

  if (activeSessionElapsed > 0) {
    activeSession = (
      <span className="blinking">{timeAsFormatted(activeSessionElapsed, true)}</span>
    )
  }

  return(
    <div className="timer-container">
      <div className="timer-name">{props.name}</div>
      <div className="timer" style={props.style}>
        <div className="timer-time-left-of-planned" style={{width: timeLeftInUnitAsPercent(props.elapsed + activeSessionElapsed, props.duration) + '%'}}></div>
        {/* <div className="timer-overage-of-planned" style={{width: timeOverageInUnitAsPercent(props.elapsed + activeSessionElapsed, props.duration) + '%'}}></div> */}

        <div className="timer-info" onClick={props.ownerView ? () => {props.handleShowTimer(props.timer)} : null} style={{marginLeft: timeLeftInUnitAsPercent(props.elapsed + activeSessionElapsed, props.duration) + '%'}}>
          {/* <div className="timer-name">{props.name}</div> */}

          <div className="timer-elapsed-and-duration">
            {timeAsFormatted(props.duration - (props.elapsed + activeSessionElapsed))}
          </div>

          <div>{activeSession}</div>
        </div>

        {button}
      </div>
    </div>
  )
}

export default Timer;