import * as comparisons from "helpers/comparisons";
import * as notifications from 'helpers/notifications';
import { addIdsToObjects } from 'helpers/iterators';
import { firebase, databaseRef } from "vendors/firebase";

function addDisplayNameForUser(user) {
  databaseRef.collection("users").doc(user.uid).update({
    displayName: user.displayName,
  })
}

function getTimersForUser(userId) {
  databaseRef.collection("timers")
    .where("userId", "==", userId)
    .where("archived", "==", false)
    .onSnapshot((querySnapshot) => {
      const timers = addIdsToObjects(querySnapshot);

      this.setState({
        timers: timers.sort(comparisons.forPositions),
      });
    });
}

function getFolloweesForUser(userId) {
  databaseRef.collection("users").where("followers", "array-contains", userId)
    .onSnapshot((querySnapshot) => {
      const followees = addIdsToObjects(querySnapshot);
    
      notifications.sendForActivity(querySnapshot.docChanges(), this.state.followees);
    
      this.setState({
        followees: followees.sort(comparisons.forStatus),
      });
    });
}

function getRequestsAsSenderForUser(userId) {
  databaseRef.collection("users").where("requestsAsRecipient", "array-contains", userId)
    .onSnapshot((querySnapshot) => {
      var requestsAsSender = [];

      querySnapshot.forEach((doc) => {
        let requestAsSender = doc.data();
        requestAsSender.id = doc.id;
        requestAsSender.invited = false;
        requestsAsSender.push(requestAsSender);
      });

      this.setState({
        requestsAsSender: requestsAsSender,
      });
    });
}

function getInvitesAsSenderForUser(userId) {
  databaseRef.collection("invites").where("senderId", "==", userId).where("recipientSignedUp", "==", false)
    .onSnapshot((querySnapshot) => {
      var invitesAsSender = [];

      querySnapshot.forEach((doc) => {
        let invite = doc.data();
        invite.id = doc.id;
        invite.invited = true;
        invitesAsSender.push(invite);
      });

      this.setState({
        invitesAsSender: invitesAsSender,
      });
    });
}

function getSettingsFollowersAndRequestsAsRecipient(userId) {
  // TODO: this is called twice on load probably because the user's status changes
  databaseRef.collection("users").doc(userId).onSnapshot((doc) => {
    let followers = [];
    let requestsAsRecipient = [];
    let photoURL = doc.data()?.photoURL;
    let dailySleepingHours = doc.data()?.dailySleepingHours || 28800000;
    let timeZone = doc.data()?.timeZone || "Etc/UTC";

    if (doc.exists) {
      if (doc.data().followers?.length > 0) {
        databaseRef.collection("users").where(firebase.firestore.FieldPath.documentId(), 'in', doc.data().followers).get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let follower = doc.data();
              follower.id = doc.id;
              followers.push(follower);
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (doc.data().requestsAsRecipient?.length > 0) {
        databaseRef.collection("users").where(firebase.firestore.FieldPath.documentId(), 'in', doc.data().requestsAsRecipient).get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let requestAsRecipient = doc.data();
              requestAsRecipient.id = doc.id;
              requestsAsRecipient.push(requestAsRecipient);
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    this.setState({
      dailySleepingHours: dailySleepingHours,
      followers: followers,
      photoURL: photoURL,
      requestsAsRecipient: requestsAsRecipient,
      timeZone: timeZone,
    });
  });
}

export {
  addDisplayNameForUser,
  getFolloweesForUser,
  getInvitesAsSenderForUser,
  getRequestsAsSenderForUser,
  getSettingsFollowersAndRequestsAsRecipient,
  getTimersForUser,
}