import React, { useEffect } from "react";
import { startFirebaseUI } from 'vendors/firebaseui';
import logo from 'assets/logo.png';
import { Layout } from 'antd';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

import 'firebaseui/dist/firebaseui.css';

const { Header, Footer, Content } = Layout;

const Authentication = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');

    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', '#e1e1e1');
    }
    
    if (currentUser) {
      navigate("/dashboard");
    } else {
      startFirebaseUI('#firebaseui');
    }
  }, [currentUser, navigate]);

  return(
    <Layout className="layout">
      <Header className="header" style={{textAlign: "center", height: "98.047px"}}>
        <img src={logo} alt="Thymefull Logo" style={{display: "inline-block", width: "300px", margin: "30px 0"}}></img>
      </Header>
      <Content className="content">
        <div id="firebaseui"></div>
      </Content>
      <Footer className="footer">
        ©2020-2024 Xtrasomatic, LLC (dba Thymefull). All rights reserved.
      </Footer>
    </Layout>
  )
}

export default Authentication;