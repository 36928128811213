import React from 'react';
import moment from 'moment';
import update from 'immutability-helper';
import { firebase, storage, databaseRef } from 'vendors/firebase';
import * as timeCalculators from 'helpers/timeCalculators';
import * as notifications from 'helpers/notifications';
import * as data from "services/index";
import logo from 'assets/logo.png';
import { Modal, Avatar, Badge, Row, Col, Button, List, Menu, Dropdown, Layout } from 'antd';
import { TeamOutlined, MenuOutlined, BellOutlined, DownOutlined, SettingOutlined, LogoutOutlined, UserAddOutlined, PlusCircleOutlined, ClockCircleOutlined, PlayCircleOutlined, UserOutlined, QuestionCircleOutlined, ExportOutlined, DollarCircleOutlined, BlockOutlined } from '@ant-design/icons';
import TimeBar from 'components/TimeBar.js';
import TimerList from 'components/TimerList.js';
import TimerForm from 'components/TimerForm.js';
import Subscription from 'components/Subscription.js';
import SettingsForm from 'components/SettingsForm.js';
import ArchivedTimers from 'components/ArchivedTimers.js';
import AccountForm from 'components/AccountForm.js';
import FollowForm from 'components/FollowForm.js';
import UserShow from 'components/UserShow.js';
import SessionForm from 'components/SessionForm.js'
import TimeLeft from 'components/TimeLeft.js'
import { AuthContext } from 'contexts/AuthContext';

import 'moment-duration-format';
import 'firebaseui/dist/firebaseui.css';
import 'styles/index.less'

const { Header, Footer, Content } = Layout;


function displayNameOrEmail(user) {
  return user.displayName || user.email
}

class Dashboard extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super();

    this.defaultState = {
      dailySleepingHours: null,
      followees: [],
      followers: [],
      invitesAsSender: [],
      photoURL: undefined,
      requestsAsRecipient: [],
      requestsAsSender: [],
      sessionBeingEdited: undefined,
      showAccountForm: false,
      showArchivedTimers: false,
      showFollowForm: false,
      showFollowersList: false,
      showSessionForm: false,
      showSettingsForm: false,
      showTimerForm: false,
      timeLeftInWeek: 0,
      timeLeftInWeekOfPlanned: 0,
      timeZone: null,
      timerBeingEdited: undefined,
      timerBeingShown: undefined,
      timers: [],
      userBeingShownId: null,
      wakingTimeLeftInWeek: 0,
    }

    this.state = this.defaultState;
  }

  componentDidMount() {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');

    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', '#e1e1e1');
    }

    data.addDisplayNameForUser.call(this, this.context.currentUser);
    data.getTimersForUser.call(this, this.context.currentUser.uid);
    data.getFolloweesForUser.call(this, this.context.currentUser.uid);
    data.getRequestsAsSenderForUser.call(this, this.context.currentUser.uid);
    data.getInvitesAsSenderForUser.call(this, this.context.currentUser.uid);
    data.getSettingsFollowersAndRequestsAsRecipient.call(this, this.context.currentUser.uid);

    this.timer = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  tick() {
    let timers = Array.from(this.state.timers);

    timers.forEach(function(timer) {
      if (timer.running) {
        timer.activeSessionElapsed = Math.abs(moment(timer.activeSessionStartedAt.toDate()).diff(moment()));

        if (timer.intervalNotificationSent !== true && timer.alertInterval > 0 && timer.alertInterval < timer.activeSessionElapsed) {
          timer.intervalNotificationSent = true;
          notifications.sendForInterval(timer);
          databaseRef.collection("timers").doc(timer.id).update({intervalNotificationSent: true})
            .catch((error) => {
              console.error(error);
            });
        }

        if (timer.completeNotificationSent !== true && timer.duration < timer.elapsed + timer.activeSessionElapsed) {
          timer.completeNotificationSent = true;
          notifications.sendForComplete(timer);
          databaseRef.collection("timers").doc(timer.id).update({completeNotificationSent: true})
            .catch((error) => {
              console.error(error);
            });
        }
      }
    });

    this.setState({
      timeLeftInWeek: timeCalculators.timeLeftInWeek(this.state.timeZone),
      timeLeftInWeekOfPlanned: timeCalculators.timeLeftInWeekOfPlanned(this.state.timers),
      timers: timers,
      wakingTimeLeftInWeek: timeCalculators.wakingTimeLeftInWeek(this.state.dailySleepingHours, this.state.timeZone),
    });
  }

  handleTimerStatusChange = (timerId) => {
    const timerToChange = this.state.timers.find(timer => timer.id === timerId);
    const runningTimer = this.state.timers.find(timer => timer.running === true);

    if (timerToChange.running === true) {
      this.handleStop(timerToChange.id);
    } else {
      if (runningTimer) {
        this.handleStop(runningTimer.id)
          .then(() => {
            this.handlePlay(timerToChange.id);
          });
      } else {
        this.handlePlay(timerToChange.id);
      }
    }
  }

  handlePlay = (timerId) => {
    const indexOfTimer = this.state.timers.findIndex(timer => timer.id === timerId);
    const nameOfTimer = this.state.timers.find(timer => timer.id === timerId).name;
    const userRef = databaseRef.collection("users").doc(this.context.currentUser.uid);
    const timerRef = databaseRef.collection("timers").doc(timerId);
    const sessionRef = databaseRef.collection("timers").doc(timerId).collection("sessions").doc();
    const batch = databaseRef.batch();
    const startedAt = firebase.firestore.Timestamp.now();

    batch.update(
      userRef,
      {
        timerRunning: true,
      }
    );

    batch.update(
      timerRef,
      {
        activeSessionId: sessionRef.id,
        activeSessionStartedAt: startedAt,
        running: true,
      }
    );

    batch.set(
      sessionRef,
      {
        duration: 0,
        endedAt: null,
        startedAt: startedAt,
        timerId: timerId,
        timerName: nameOfTimer,
        userId: this.context.currentUser.uid,
      }
    );

    batch.commit()
      .then(() => {
        this.setState({
          timers: update(this.state.timers, {[indexOfTimer]: {
            activeSessionId: {$set: sessionRef.id},
            activeSessionStartedAt: {$set: startedAt},
            running: {$set: true}
          }})
        })
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
  }

  handleStop = (timerId) => {
    return new Promise((resolve, reject) => {
      const indexOfTimer = this.state.timers.findIndex(timer => timer.id === timerId);
      const timer = this.state.timers[indexOfTimer];
      let startedAt;
      const endedAt = firebase.firestore.Timestamp.now();
  
      databaseRef.runTransaction((transaction) => {
        const userRef = databaseRef.collection("users").doc(this.context.currentUser.uid);
        const timerRef = databaseRef.collection("timers").doc(timerId);
        const sessionRef = databaseRef.collection("timers").doc(timerId).collection("sessions").doc(this.state.timers[indexOfTimer].activeSessionId);
  
        return transaction.get(sessionRef).then((session) => {
          startedAt = session.data().startedAt;
          const duration = Math.abs(moment(startedAt.toDate()).diff(moment(endedAt.toDate())));
  
          transaction.update(
            userRef,
            {
              timerRunning: false,
            }
          );
  
          transaction.update(
            timerRef,
            {
              activeSessionElapsed: 0,
              activeSessionId: null,
              activeSessionStartedAt: null,
              elapsed: timer.elapsed + timer.activeSessionElapsed,
              intervalNotificationSent: false,
              lastSessionEndedAt: endedAt,
              lastSessionStartedAt: startedAt,
              running: false,
            }
          );
  
          transaction.update(
            sessionRef,
            {
              duration: duration,
              endedAt: endedAt,
            }
          );
        });
      }).then(() => {
        this.setState({
          timers: update(this.state.timers, {[indexOfTimer]: {
            activeSessionElapsed: {$set: 0},
            activeSessionId: {$set: null},
            activeSessionStartedAt: {$set: null},
            elapsed: {$set: timer.elapsed + timer.activeSessionElapsed},
            intervalNotificationSent: {$set: false},
            lastSessionEndedAt: {$set: endedAt},
            lastSessionStartedAt: {$set: startedAt},
            running: {$set: false},
          }})
        },
        () => {
          resolve();
        })
      }).catch((error) => {
        console.log(error);
      });
    });
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const timersFallback = Array.from(this.state.timers);
    const timers = Array.from(this.state.timers);
    const [removed] = timers.splice(result.source.index, 1);
    timers.splice(result.destination.index, 0, removed);

    let batch = databaseRef.batch();

    timers.forEach(function(timer, index) {
      timer.position = index;
      let sfRef = databaseRef.collection("timers").doc(timer.id);
      batch.update(sfRef, {position: timer.position});
    });

    this.setState({
      timers: timers
    });

    batch.commit().catch(function(error) {
      console.error("Error writing document: ", error);
      this.setState({
        timers: timersFallback
      });
    });
  }

  handleTimerForm = (timer) => {
    this.setState({
      timerBeingEdited: timer === undefined ? undefined : timer,
      showTimerForm: !this.state.showTimerForm,
    })
  }

  handleSessionForm = (session) => {
    this.setState({
      sessionBeingEdited: session === undefined ? undefined : session,
      showSessionForm: !this.state.showSessionForm,
    })
  }

  handleSettingsForm = () => {
    this.setState({showSettingsForm: !this.state.showSettingsForm});
  }

  handleArchivedTimers = () => {
    this.setState({showArchivedTimers: !this.state.showArchivedTimers});
  }

  handleAccountForm = () => {
    this.setState({showAccountForm: !this.state.showAccountForm});
  }

  handleFollowForm = () => {
    this.setState({showFollowForm: !this.state.showFollowForm});
  }

  handleAccept = (userId) => {
    databaseRef.collection("users").doc(this.context.currentUser.uid).update({
      requestsAsRecipient: firebase.firestore.FieldValue.arrayRemove(userId),
      followers: firebase.firestore.FieldValue.arrayUnion(userId)
    });
  }

  handleDeny = (userId) => {
    databaseRef.collection("users").doc(this.context.currentUser.uid).update({
      requestsAsRecipient: firebase.firestore.FieldValue.arrayRemove(userId)
    });
  }

  handleFollowersList = () => {
    this.setState({showFollowersList: !this.state.showFollowersList});
  }

  handleStopBeingFollowed = (userId) => {
    databaseRef.collection("users").doc(this.context.currentUser.uid).update({
      followers: firebase.firestore.FieldValue.arrayRemove(userId)
    });
  }

  handleUserShow = (user) => {
    this.setState({
      userBeingShownId: user === null ? null : user.id
    })
  }

  handleTimerShow = (timer) => {
    this.setState({
      timerBeingShown: timer === undefined ? undefined : timer
    })
  }

  handleSignOut() {
    firebase.auth().signOut();
    firebase.database().goOffline();
  }

  showSubscriptionOptions() {
    const isPastFreeTrialLimit = moment().diff(moment(this.context.currentUser.metadata.creationTime), 'days') > 7
    const doesNotHaveActiveSubscription = this.context.subscription.status !== "active";
    
    return isPastFreeTrialLimit && doesNotHaveActiveSubscription;
  }

  exportData = () => {
    let uid = this.context.currentUser.uid;
    let functionPath;

    if (window.location.hostname === "localhost") {
      functionPath = "http://localhost:5001/thymefull/us-central/https-exportData";
    } else {
      functionPath = "https://us-central1-thymefull.cloudfunctions.net/https-exportData";
    }

    fetch(functionPath, {
      method: "post",
      mode: "cors",
      body: JSON.stringify({uid: uid})
    })
      .then((response) => {
        console.log(response.json());
      })
      .then((data) => {
        storage.ref(`exportData/${uid}/export.json`).getDownloadURL()
          .then((url) => {
            window.open(url, '_blank');
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async manageSubscriptions() {
    const functionRef = firebase
      .app()
      .functions('us-central1')
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');

    const { data } = await functionRef({ returnUrl: window.location.origin });

    window.location.assign(data.url);
  }

  render() {
    let header;
    let content;

    if (this.context.currentUser) {
      header = (
        <Row>
          <Col xs={{span: 24}} md={{span: 12, offset: 6}}>
            {this.state.followees.map((followee, index) => {
              let opacity;
              let count;

              if (followee.status === "online") {
                opacity = "1.0"
              } else {
                opacity = "0.3"
              }

              if (followee.timerRunning) {
                count = <ClockCircleOutlined style={{ color: 'white', fontSize: "28px" }} />;
              } else {
                count = 0;
              }

              return(
                <div key={followee.id} style={{display: "inline-block"}}>
                  <Badge
                    count={count}
                    offset={[-21, 16]}
                    onClick={() => {this.handleUserShow(followee)}}
                    style={{cursor: "pointer"}}
                  >
                    <Avatar
                      style={{
                        backgroundColor: "gray",
                        color: "white",
                        cursor: "pointer",
                        marginRight: "5px",
                        opacity: opacity,
                      }}
                      onClick={() => {this.handleUserShow(followee)}}
                      src={followee.photoURL}
                    >
                      {followee.email[0].toUpperCase()}
                    </Avatar>
                  </Badge>

                  <UserShow
                    currentUser={this.context.currentUser}
                    handleUserShow={this.handleUserShow}
                    user={followee}
                    visible={this.state.userBeingShownId === followee.id}
                  />
                </div>
              )
            })}

            <Button shape="circle" icon={<UserAddOutlined />} onClick={this.handleFollowForm} />

            <div style={{float: "right", display: "inline-block"}}>
              <Dropdown
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <Menu.Item key="1" onClick={() => this.handleTimerForm()}><ClockCircleOutlined /> New Timer</Menu.Item>
                    <Menu.Item key="2" onClick={() => this.handleSessionForm()} disabled={this.state.timers.length === 0}><PlayCircleOutlined /> New Session</Menu.Item>
                  </Menu>
                }
              >
                <Button shape="circle" icon={<PlusCircleOutlined />} style={{marginLeft: "5px"}}/>
              </Dropdown>

              <Dropdown
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu
                    style={{padding: "0 12px", minWidth: "325px"}}
                  >
                    <List
                      header={<div>Notifications</div>}
                      itemLayout="horizontal"
                      dataSource={this.state.requestsAsRecipient}
                      renderItem={item => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={
                              <Avatar
                                style={{
                                  color: "white",
                                  backgroundColor: "lightgray"
                                }}
                                src={item.photoURL}
                              >
                                {item.email[0].toUpperCase()}
                              </Avatar>
                            }
                            description={
                              <>
                                <div>{displayNameOrEmail(item)} wants to follow you</div>
                                <Button type="link" size="small" style={{marginRight: "5px"}} onClick={() => { this.handleAccept(item.id) }}>Accept</Button>
                                <Button type="link" size="small" danger onClick={() => { this.handleDeny(item.id) }}>Deny</Button>
                              </>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Menu>
                }
              >
                <Badge count={this.state.requestsAsRecipient.length} dot offset={[-4, 6]}>
                  <Button shape="circle" icon={<BellOutlined />} style={{marginLeft: "5px"}} />
                </Badge>
              </Dropdown>

              <Dropdown
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <Menu.Item key="1" onClick={this.handleSettingsForm}><SettingOutlined /> Settings</Menu.Item>
                    <Menu.Item key="2" onClick={this.handleFollowersList}><TeamOutlined /> Followers</Menu.Item>
                    <Menu.Item key="3" onClick={this.handleArchivedTimers}><BlockOutlined /> Archived</Menu.Item>
                    <Menu.Item key="4" onClick={this.handleAccountForm}><UserOutlined /> Account</Menu.Item>
                    <Menu.Item key="5" onClick={this.manageSubscriptions}><DollarCircleOutlined /> Billing</Menu.Item>
                    <Menu.Item key="6" onClick={this.exportData}><ExportOutlined /> Export Data</Menu.Item>
                    <Menu.Item key="7"><QuestionCircleOutlined /> <a href="mailto:team@thymefull.com?subject=Thymefull" target="_blank" rel="noopener noreferrer" style={{display: "inline"}}>Help</a></Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="8" onClick={this.handleSignOut} danger><LogoutOutlined /> Sign out</Menu.Item>
                  </Menu>
                }
              >
                <Button icon={<MenuOutlined />} style={{marginLeft: "10px"}}>
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </Col>
        </Row>
      )

      content = (
        <>
          <Row>
            <Col xs={{span: 24}} md={{span: 12, offset: 6}}>
              <TimerList
                timers={this.state.timers}
                handleTimerStatusChange={this.handleTimerStatusChange}
                handleStop={this.handleStop}
                onDragEnd={this.onDragEnd}
                handleTimerForm={this.handleTimerForm}
                updateTimer={this.updateTimer}
                currentUser={this.context.currentUser}
                handleSessionForm={this.handleSessionForm}
                handleTimerShow={this.handleTimerShow}
              />
            </Col>
          </Row>

          <SettingsForm
            currentUser={this.context.currentUser}
            dailySleepingHours={this.state.dailySleepingHours}
            handleSettingsForm={this.handleSettingsForm}
            photoURL={this.state.photoURL}
            visible={this.state.showSettingsForm}
            timeZone={this.state.timeZone}
          />

          <ArchivedTimers
            currentUser={this.context.currentUser}
            handleArchivedTimers={this.handleArchivedTimers}
            visible={this.state.showArchivedTimers}
          />

          <AccountForm
            currentUser={this.context.currentUser}
            handleAccountForm={this.handleAccountForm}
            photoURL={this.state.photoURL}
            storage={storage}
            visible={this.state.showAccountForm}
          />

          <FollowForm
            currentUser={this.context.currentUser}
            followees={this.state.followees}
            handleFollowForm={this.handleFollowForm}
            invitesAsSender={this.state.invitesAsSender}
            requestsAsSender={this.state.requestsAsSender}
            show={this.state.showFollowForm}
          />

          <TimerForm
            currentUser={this.context.currentUser}
            handleTimerForm={this.handleTimerForm}
            handleTimerShow={this.handleTimerShow}
            timer={this.state.timerBeingEdited}
            visible={this.state.showTimerForm}
            weekStartsOn={this.state.weekStartsOn}
          />

          <SessionForm
            currentUser={this.context.currentUser}
            handleSessionForm={this.handleSessionForm}
            session={this.state.sessionBeingEdited}
            timers={this.state.timers}
            visible={this.state.showSessionForm}
          />

          <Modal
            title="Followers"
            open={this.state.showFollowersList}
            onCancel={this.handleFollowersList}
            footer={null}
          >
            <List
              header={null}
              itemLayout="horizontal"
              dataSource={this.state.followers}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        style={{
                          color: "white",
                          backgroundColor: "lightgray"
                        }}
                        src={item.photoURL}
                      >
                        {item.email[0]}
                      </Avatar>
                    }
                    description={
                      <>
                        <div>You're being followed by {item.email}.</div>
                        <Button type="link" size="small" danger onClick={() => { this.handleStopBeingFollowed(item.id) }}>Stop being followed</Button>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Modal>
        </>
      )
    } else {
      // content = (
      //   <header>
      //     <div class="overlay"></div>
      //     <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
      //       <source src="https://storage.googleapis.com/coverr-main/mp4/Mt_Baker.mp4" type="video/mp4" />
      //     </video>
      //     <div class="container h-100">
      //       <div class="d-flex h-100 text-center align-items-center">
      //         <div class="w-100 text-white">
      //           <h1 class="display-3">Thymefull</h1>
      //           <p class="lead mb-0">Commit. Flexibly.</p>
      //         </div>
      //       </div>
      //     </div>
      //   </header>
      // )
    }

    return(
      <div className="App">

        <div id="firebaseui"></div>

        <Layout className="layout">
          <Header className="header">
            {header}
          </Header>
          
          <Row>
            <Col xs={{span: 24}} md={{span: 12, offset: 6}}>
              <TimeLeft
                showWakingTime={this.context.currentUser !== null}
                style={{marginBottom: "60px"}}
                timeLeftInWeek={this.state.timeLeftInWeek}
                wakingTimeLeftInWeek={this.state.wakingTimeLeftInWeek}
              >
                <TimeBar
                  currentUser={this.context.currentUser}
                  timeLeftInWeek={this.state.timeLeftInWeek}
                  wakingTimeLeftInWeek={this.state.wakingTimeLeftInWeek}
                  timeLeftInWeekOfPlanned={this.state.timeLeftInWeekOfPlanned}
                  style={{marginBottom: "4px"}}
                />
              </TimeLeft>
            </Col>
          </Row>

          <Content className="content">
            {content}
            <Subscription currentUser={this.context.currentUser} visible={this.showSubscriptionOptions()}/>
          </Content>
          <Footer className="footer">
            <img src={logo} alt="Thymefull Logo" style={{width: "300px", marginBottom: "30px", maxWidth: "100%"}}></img>
            <br />
            ©2020-2024 Xtrasomatic, LLC (dba Thymefull). All rights reserved.
          </Footer>
        </Layout>
      </div>
    );
  }
}

export default Dashboard;
