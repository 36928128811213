import React, { useEffect } from 'react';
import logo from 'assets/logo.png';
import timers from 'assets/features/timers.png';
import aggregate from 'assets/features/aggregate.png';
import interval from 'assets/features/interval.png';
import history from 'assets/features/history.png';
import following from 'assets/features/following.png';
import alerts from 'assets/features/alerts.png';
import screenshotMobile from 'assets/screenshot-mobile.png';
import screenshotDesktop from 'assets/screenshot-desktop.png';
import { Link } from "react-router-dom";
import moment from 'moment';
import { Layout, Row, Col, Button, Typography, Statistic, Space } from 'antd';

import 'moment-duration-format';
import 'styles/index.less'
import 'styles/Landing.less'

const { Header, Footer, Content } = Layout;
const { Title, Paragraph, Text } = Typography;
const { Countdown } = Statistic;

const endOfDay = moment().endOf("day");
const endOfWeek = moment().endOf("week");
const endOfYear = moment().endOf("year");

function Landing() {
  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');

    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', '#001529');
    }
  }, []);

  return(
    <Layout className="layout landing">
      <Header className="landing-header">
        <Row justify="center">
          <Col xs={{span: 24}} md={{span: 16}}>
            <Row>
              <Col xs={{span: 24}} md={{span: 12}} className="logo-container">
                <img src={logo} alt="Thymefull Logo" style={{display: "inline-block", width: "300px", margin: "15px 0", maxWidth: "100%"}}></img>
              </Col>
              <Col xs={{span: 24}} md={{span: 12}} className="authorization-button-container">
                <Link to="/authentication" style={{float: "right"}}>
                  <Button type="primary" shape="round" size={"large"} style={{margin: "14.025px 0"}}>
                    Signin / Signup
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>

      <Content className="content">
        <Row className="countdowns-container" justify="center">
          <Col xs={{span: 24}} md={{span: 16}}>
            <Row>
              <Col xs={{span: 24}} md={{span: 8}}>
                <Countdown title="Hours until this day is over" value={endOfDay} format={"HH:mm:ss"} />
              </Col>
              <Col xs={{span: 24}} md={{span: 8}}>
                <Countdown title="Hours until this week is over" value={endOfWeek} valueStyle={{}} format={"HH:mm:ss"} />
              </Col>
              <Col xs={{span: 24}} md={{span: 8}}>
                <Countdown title="Hours until this year is over" value={endOfYear} valueStyle={{}} format={"HH:mm:ss"} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={{span: 24}} md={{span: 16}}>
            <Row justify="center">
              <Col xs={{span: 20}} md={{span: 24}}>
                <div style={{textAlign: "center", marginBottom: "80px"}}>
                  <h1 className="landing-title" style={{margin: "0 0 10px 0"}}>
                    Time doesn't wait. <br className="landing-title-break"/>Neither should you.
                  </h1>
                  <h2 className="landing-subtitle" style={{margin: "0 0 80px 0"}}>
                    Thymefull gives you the focus you need to create meaningful deep work and accomplish your life's goals.
                  </h2>
                  <div style={{fontSize: "16px", marginBottom: "5px"}}>
                    $9.99 / month
                  </div>
                  <Link to="/authentication">
                    <Button type="primary" shape="round" size={"large"}>
                      Try it free for 7 days
                    </Button>
                  </Link>
                  <div style={{marginTop: "5px"}}>
                    No credit card required
                  </div>
                </div>
              </Col>
              <Col xs={{span: 24}}>
                <div style={{textAlign: "center"}}>
                  <img className="screenshot-mobile" src={screenshotMobile} alt="Thymefull Screenshot"></img>
                </div>
                <div style={{textAlign: "center"}}>
                  <img className="screenshot-desktop" src={screenshotDesktop} alt="Thymefull Screenshot"></img>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="center" style={{paddingTop: "80px"}} className="leaf-texture">
          <Col xs={{span: 24}} md={{span: 16}}>
            <Row align="middle" justify="center" gutter={[{md: 80}, {xs: 40, md: 80}]}>
              <Col xs={{span: 20, order: 1}} sm={{span: 12, order: 1}}>
                <Title level={3}>Create weekly timers for deep work that will move you closer to your goals.</Title>
                <Paragraph className="feature-description">Without scheduling deep work on particular days and times during the week, you're able to commit to your goals while still remaining flexible.</Paragraph>
              </Col>
              <Col xs={{span: 20, order: 2}} sm={{span: 12, order: 2}}>
                <div style={{textAlign: "center"}}>
                  <img src={timers} alt="Timers" style={{display: "inline-block", width: "100%"}}></img>
                </div>
              </Col>

              <Col xs={{span: 20, order: 4}} sm={{span: 12, order: 3}}>
                <div style={{textAlign: "center"}}>
                  <img src={aggregate} alt="Aggregate" style={{display: "inline-block", width: "100%"}}></img>
                </div>
              </Col>
              <Col xs={{span: 20, order: 3}} sm={{span: 12, order: 4}}>
                <Title level={3}>See an aggregate of all of your weekly timers, how much waking time is left in the week, and how much general time is left in the week.</Title>
                <Paragraph className="feature-description">With this kind of insight you'll know exactly how much work you have to do to stay ahead of your commitments. You'll also be able to play in peace.</Paragraph>
              </Col>

              <Col xs={{span: 20, order: 5}} sm={{span: 12, order: 5}}>
                <Title level={3}>Create custom alert intervals for your running timers.</Title>
                <Paragraph className="feature-description">Commit to focusing on a single activity before you're alerted to your running timer. Build focus. Make progress.</Paragraph>
              </Col>
              <Col xs={{span: 20, order: 6}} sm={{span: 12, order: 6}}>
                <div style={{textAlign: "center"}}>
                  <img src={interval} alt="Interval" style={{display: "inline-block", width: "100%"}}></img>
                </div>
              </Col>

              <Col xs={{span: 20, order: 8}} sm={{span: 12, order: 7}}>
                <div style={{textAlign: "center"}}>
                  <img src={following} alt="Following" style={{display: "inline-block", width: "100%"}}></img>
                </div>
              </Col>
              <Col xs={{span: 20, order: 7}} sm={{span: 12, order: 8}}>
                <Title level={3}>Follow—and be followed by—family and friends.</Title>
                <Paragraph className="feature-description">You can each peek in on each others' weekly progress in real-time. And no matter where you each are in the world, your progress will appear in your respective time zones.</Paragraph>
              </Col>

              <Col xs={{span: 20, order: 9}} sm={{span: 12, order: 9}}>
                <Title level={3}>Get alerted in real-time whenever a family or friend starts or stops a timer.</Title>
                <Paragraph className="feature-description">Work uninterupted. And know when not to interupt.</Paragraph>
              </Col>
              <Col xs={{span: 20, order: 10}} sm={{span: 12, order: 10}}>
                <div style={{textAlign: "center"}}>
                  <img src={alerts} alt="Alerts" style={{display: "inline-block", width: "100%"}}></img>
                </div>
              </Col>

              <Col xs={{span: 20, order: 12}} sm={{span: 12, order: 11}}>
                <div style={{textAlign: "center"}}>
                  <img src={history} alt="History" style={{display: "inline-block", width: "100%"}}></img>
                </div>
              </Col>
              <Col xs={{span: 20, order: 11}} sm={{span: 12, order: 12}}>
                <Title level={3}>See a history of your activity.</Title>
                <Paragraph className="feature-description">See a week by week history of how much time you've applied toward your goals.</Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>

      <Footer className="landing-footer">
        <Row justify="center">
          <Col xs={{span: 24}} md={{span: 16}}>
            <Row justify="center">
              <Col xs={{span: 24, order: 3}} md={{span: 24, order: 1}}>
                <img src={logo} alt="Thymefull Logo" style={{display: "inline-block", width: "150px", marginBottom: "15px", maxWidth: "100%"}}></img>
                <div style={{marginBottom: "12px"}}>
                  <Text style={{color: 'white'}}>©2020-2024 Xtrasomatic, LLC (dba Thymefull). All rights reserved.</Text>
                </div>
                <Space>
                  <Link to="/terms-of-service.pdf" target="_blank">
                    <Text underline style={{color: 'white'}}>Terms of Service</Text>
                  </Link>
                  <Link to="/privacy-policy.pdf" target="_blank">
                    <Text underline style={{color: 'white'}}>Privacy Policy</Text>
                  </Link>
                  <a href="mailto:team@thymefull.com?subject=Thymefull" target="_blank" rel="noopener noreferrer">
                    <Text underline style={{color: 'white'}}>Help</Text>
                  </a>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
}

export default Landing;
