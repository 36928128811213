import React from 'react';
import {databaseRef} from '../vendors/firebase';
import moment from 'moment';
import 'moment-duration-format';
import {Button, Timeline, Tag} from 'antd';
import {PlusCircleOutlined, EditOutlined, LeftOutlined, RightOutlined} from '@ant-design/icons';
import SessionForm from"components/SessionForm.js"

function timeAsFormatted(time) {
  return moment.duration(time).format('HH:mm', {trim: false});
}

class SessionList extends React.Component {
  constructor(props) {
    super(props);

    this.sessionsRef = databaseRef.collection("timers").doc(this.props.timer.id).collection("sessions").orderBy("startedAt");
    this.weeksFromCurrent = 0;

    this.state = {
      endOfWeek: undefined,
      sessionBeingEdited: undefined,
      sessions: [],
      showSessionForm: false,
      startOfWeek: undefined,
    }
  }

  componentDidMount() {
    this.currentWeek();
  }

  prevWeek = () => {
    this.weeksFromCurrent -= 1;
    this.changeWeek();
  }

  currentWeek = () => {
    this.weeksFromCurrent = 0;
    this.changeWeek();
  }

  nextWeek = () => {
    this.weeksFromCurrent += 1;
    this.changeWeek();
  }

  changeWeek = () => {
    const date = moment().add(this.weeksFromCurrent, "weeks");
    const startOfWeek = date.clone().startOf('week').toDate();
    const endOfWeek = date.clone().endOf("week").toDate();
    this.getSessions(startOfWeek, endOfWeek);
  }

  getSessions = (startOfWeek, endOfWeek) => {
    if (this.unsubscribe !== undefined) {
      this.unsubscribe();
    }

    this.unsubscribe = this.sessionsRef.where("startedAt", ">=", startOfWeek).where("startedAt", "<=", endOfWeek)
      .onSnapshot((querySnapshot) => {
        let sessions = [];

        querySnapshot.forEach((doc) => {
          const session = doc.data();
          session.id = doc.id;
          sessions.push(session);
        });

        this.setState({
          endOfWeek: endOfWeek,
          sessions: sessions,
          startOfWeek: startOfWeek,
        });
      })
  }

  handleSessionForm = (session) => {
    this.setState({
      sessionBeingEdited: session === undefined ? undefined : session,
      showSessionForm: !this.state.showSessionForm,
    })
  }

  render() {
    let pending;

    if (this.props.timer.running && this.weeksFromCurrent === 0) {
      pending = true
    }

    return(
      <>
        <div style={{marginBottom: "26px", textAlign: "center"}}>
          <Button shape="circle" icon={<PlusCircleOutlined />} style={{margin: "0 2.5px"}} onClick={() => this.handleSessionForm()} />
          <Button shape="circle" icon={<EditOutlined />} style={{margin: "0 2.5px"}} onClick={() => this.props.handleTimerForm(this.props.timer)} />
        </div>

        <div style={{marginBottom: "26px", textAlign: "center"}}>
          <Button shape="circle" icon={<LeftOutlined />} style={{margin: "0 2.5px"}} onClick={this.prevWeek} />
          <Button style={{margin: "0 2.5px"}} onClick={this.currentWeek}>This Week</Button>
          <Button shape="circle" icon={<RightOutlined />} style={{margin: "0 2.5px"}} onClick={this.nextWeek} />
        </div>

        <div style={{marginBottom: "34px", textAlign: "center", fontWeight: "bold"}}>{moment(this.state.startOfWeek).format("DD/MM/YY")} - {moment(this.state.endOfWeek).format("DD/MM/YY")}</div>

        <Timeline
          mode={"left"}
          pending={pending}
          reverse={true}
        >
          {this.state.sessions.map((session) => {
            if (session.endedAt !== null) {
              return(
                <Timeline.Item
                  key={session.id}
                  onClick={() => {this.handleSessionForm(session)}}
                  style={{cursor: "pointer"}}
                  label={
                    <span style={{paddingRight: "22px", fontSize: "12px"}}>
                      {moment(session.startedAt.toDate()).format("DD/MM/YY hh:mm A")}
                    </span>
                  }
                  dot={<Tag style={{margin: "0", cursor: "pointer"}}>{timeAsFormatted(session.duration)}</Tag>}
                >
                  <span style={{paddingLeft: "14px", fontSize: "12px"}}>
                    {moment(session.endedAt.toDate()).format("DD/MM/YY hh:mm A")}
                  </span>
                </Timeline.Item>
              )
            } else {
              return(
                <Timeline.Item
                  key={1}
                  style={{opacity: "0"}}
                  label={<span>{moment().format("DD/MM/YY hh:mm A")}</span>}
                >
                </Timeline.Item>
              )
            }
          })}
        </Timeline>

        <SessionForm
          handleSessionForm={this.handleSessionForm}
          session={this.state.sessionBeingEdited}
          timer={this.props.timer}
          visible={this.state.showSessionForm}
          currentUser={this.props.currentUser}
        />
      </>
    )
  }
}

export default SessionList;