import React from "react";
import {Modal, Card, Alert} from "antd";
import {loadStripe} from '@stripe/stripe-js';
import "../styles/Subscription.less";
import {databaseRef} from '../vendors/firebase';

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prices: []
    }
  }

  componentDidMount() {
    databaseRef.collection('products').where('active', '==', true).where('name', '==', 'Thymefull Subscription').get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          const priceSnap = await doc.ref.collection('prices').get();
          let prices = [];

          priceSnap.docs.forEach((doc) => {
            let price = doc.data();
            price.id = doc.id;
            prices.push(price);
          });

          this.setState({prices: prices})
        });
      });
  }

  async checkOut(priceId) {
    console.log(priceId);
    const docRef = await databaseRef
      .collection('customers')
      .doc(this.props.currentUser.uid)
      .collection('checkout_sessions')
      .add({
        price: priceId,
        // trial_from_plan: false,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        // Show an error to your customer and 
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        const stripe = await loadStripe('pk_live_51OcY5WEGrYJiPePlGJ6h2FUuR0cmqnOVdyykygJatITCiaPsCBtcTaNag0iPZSmEIHmD9ObMg3j8fFsGz5MYKDsr00tXsMqQGv');
        // const stripe = await loadStripe('pk_test_51OcY5WEGrYJiPePl8DSO0y0Ddxz2CQr3SJMdwD0TZRL84ODgpV8LlmmGOjDQGc6DyQXX74oCm5U99Z9aGTa20sBe00Otp9oHDR');
        stripe.redirectToCheckout({ sessionId });
      }
    });
  }

  render() {
    return (
      (<Modal
        footer={null}
        title="Plans"
        open={this.props.visible}
        closable={false}
      >
        {this.state.prices.map((price, index) => {
          return(
            <>
              <Alert
                message="Choose a plan below to start making real progress."
                type="info"
                style={{marginBottom: '24px'}}
              />
              <Card title="Standard" onClick={() => this.checkOut(price.id)} style={{cursor: 'pointer'}} hoverable={true}>
                <p style={{margin: '0'}}>{`$${price.unit_amount/100} per ${price.interval_count} ${price.interval} billed ${price.interval}ly`}</p>
              </Card>
            </>
          )
        })}
      </Modal>)
    );
  }
}

export default Subscription;