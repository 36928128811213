import React from 'react';
import { databaseRef } from '../vendors/firebase';
import moment from 'moment';
import 'moment-duration-format';
import { firebase } from '../vendors/firebase';
import * as notifications from '../helpers/notifications';
// import locale from 'antd/es/date-picker/locale/en_GB';

import { Button, Modal, Form, DatePicker, Select } from 'antd';

function duration(date1, date2) {
  return Math.abs(moment(date1).diff(moment(date2)));
}

class SessionForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // TODO: Remove setTimeout and use portal
    // https://stackoverflow.com/questions/44074747/componentdidmount-called-before-ref-callback
    setTimeout(() => {
      if (this.props.session !== prevProps.session) {
        if (this.props.session !== undefined) {
          this.formRef.current.setFieldsValue({
            sessionRange: [moment(this.props.session.startedAt.toDate()), moment(this.props.session.endedAt.toDate())]
          });
        } else {
          this.formRef.current.setFieldsValue({
            sessionRange: [moment(), moment()]
          });
        }
      }
    }, 300);
  }

  handleSave = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        this.formRef.current.resetFields();

        const session = {
          duration: duration(values.sessionRange[0].toDate(), values.sessionRange[1].toDate()),
          endedAt: firebase.firestore.Timestamp.fromDate(values.sessionRange[1].toDate()),
          startedAt: firebase.firestore.Timestamp.fromDate(values.sessionRange[0].toDate()),
          timerId: this.props.timer ? this.props.timer.id : values.timer.value,
          timerName: this.props.timer ? this.props.timer.name : values.timer.label,
          userId: this.props.currentUser.uid,
        }

        if (this.props.session === undefined && this.props.timer === undefined) {
          databaseRef.collection("timers").doc(values.timer.value).collection("sessions").add(session)
            .then(() => {
              this.updateTimer(values.timer.value);
            })
        } else if (this.props.session === undefined) {
          databaseRef.collection("timers").doc(this.props.timer.id).collection("sessions").add(session)
            .then(() => {
              this.updateTimer(this.props.timer.id);
            })
        } else {
          databaseRef.collection("timers").doc(this.props.timer.id).collection("sessions").doc(this.props.session.id).update(session)
            .then(() => {
              this.updateTimer(this.props.timer.id);
            })
        }
        
        this.props.handleSessionForm();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  }

  handleDelete = () => {
    databaseRef.collection("timers").doc(this.props.timer.id).collection("sessions").doc(this.props.session.id).delete()
      .then(() => {
        this.updateTimer(this.props.timer.id);
        this.props.handleSessionForm();
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }

  handleCancel = () => {
    this.formRef.current.resetFields();
    this.props.handleSessionForm();
  }

  updateTimer = (timerId) => {
    const now = moment();
    const startOfWeek = now.startOf('isoweek').toDate();
    const timersSessionsRef = databaseRef.collection("timers").doc(timerId).collection("sessions");

    timersSessionsRef.where("endedAt", ">=", startOfWeek).orderBy("endedAt", "desc").get()
      .then((querySnapshot) => {
        let elapsed = 0;

        querySnapshot.forEach((doc) => {
          const session = doc.data();
          const sessionDuration = duration(session.startedAt.toDate(), session.endedAt.toDate());
          elapsed += sessionDuration;
        });

        timersSessionsRef.orderBy("endedAt", "desc").limit(1).get()
          .then((querySnapshot) => {
            let lastSessionStartedAt = null;
            let lastSessionEndedAt = null;

            if (querySnapshot.docs.length > 0) {
              const lastSession = querySnapshot.docs[0].data();
              lastSessionStartedAt = lastSession.startedAt;
              lastSessionEndedAt = lastSession.endedAt;
            }

            databaseRef.collection("timers").doc(timerId).get()
              .then((doc) => {
                const timerData = doc.data();
                let completeNotificationSent = timerData.completeNotificationSent;

                if (completeNotificationSent !== true && timerData.duration < elapsed) {
                  completeNotificationSent = true;
                  notifications.sendForComplete(this.props.timer);
                } else {
                  completeNotificationSent = false;
                }

                databaseRef.collection("timers").doc(timerId).update({
                  completeNotificationSent: completeNotificationSent,
                  elapsed: elapsed,
                  lastSessionEndedAt: lastSessionEndedAt,
                  lastSessionStartedAt: lastSessionStartedAt,
                })
              })
          })
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }

  render() {
    const header = this.props.session === undefined ? "New Session" : "Edit Session";
    let timerSelect;

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };

    if (this.props.timer === undefined) {
      timerSelect = (
        <Form.Item
          name="timer"
          label="Timer"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Choose a timer"
            labelInValue
          >
            {this.props.timers.map((timer) => {
              return(
                <Select.Option key={timer.id}>{timer.name}</Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      )
    }

    const { RangePicker } = DatePicker;

    const rangeConfig = {
      rules: [{ type: 'array', required: true }],
    };

    let footer = [
      <Button key="cancel" onClick={this.handleCancel}>
        Close
      </Button>,
      <Button key="ok" type="primary" onClick={this.handleSave}>
        Save
      </Button>,
    ];

    if (this.props.session !== undefined) {
      const deleteButton = (
        <Button key="delete" type="link" danger onClick={this.handleDelete}>
          Delete
        </Button>
      );

      footer.unshift(deleteButton);
    };

    return (
      (<Modal
        bodyStyle={{paddingBottom: "0"}}
        open={this.props.visible}
        title={header}
        okText="Save"
        onOk={this.handleSave}
        onCancel={this.handleCancel}
        footer={footer}
        zIndex={1001}
      >
        <Form
          ref={this.formRef}
          name="session-form"
          {...layout}
        >
          {timerSelect}

          <Form.Item
            name="sessionRange"
            label="Interval"
            {...rangeConfig}
          >
            <RangePicker
              showTime
              format="DD/MM/YY hh:mm A"
              style={{width: "100%"}}
              // locale={locale}
            />
          </Form.Item>
        </Form>
      </Modal>)
    );
  }
}

export default SessionForm;